<template>
  <div class="section pt-0">
    <div class="container">
      <div class="home-hero">
        <div class="col-md-5 side-description">
          <h1>Unidade Pastoral<br>de Chelas</h1>
          <p class="desc">A unidade Pastoral de Chelas é constituída pela Paróquia São Maximiliano Kolbe do Vale de
            Chelas e pela Paróquia de Santa Beatriz da Silva. A paróquia de São Maximiliano Kolbe do Vale de Chelas,
            além da Igreja matriz (s. Maximiliano K.) tem no seu território a Igreja de Santa Clara de Assis.
            Todas encontram-se na freguesia de Marvila e fazem parte da Vigararia “Lisboa II”. Navegue no site para
            saber mais sobre as suas igrejas,
            sacramentos, vida cristã, contactos e muito mais.
            Como em todas as paróquias, existem dois Conselhos que ajudam o pároco e os freis na orientação pastoral e
            económica da realidade eclesial: o Conselho Pastoral Paroquial (CPP) e o Conselho para os Assuntos
            Económicos (CPAE). Enquanto os CPAE devem permanecer distintos (um Conselho para cada paróquia) os CPP, de
            vez em quando, convocados pelo pároco reúnem-se num encontro unitário.
          </p>
          <div class="row">
            <div class="col-4">
              <router-link
                  :to="{'name': 'baptism'}"
                  class="btn btn-primary w-100"
              >
                Batismo
              </router-link>
            </div>
            <div class="col-4">
              <router-link
                  :to="{'name': 'marriage'}"
                  class="btn btn-primary w-100"
              >
                Matrimónio
              </router-link>
            </div>
            <div class="col-4">
              <router-link
                  :to="{'name': 'catechism'}"
                  class="btn btn-primary w-100"
              >
                Catequese
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="hero-img">
            <div class="carousel slide h-100">
              <ol class="carousel-indicators h-100">
                <li v-for="(img, index) in heroImages" :key="index"
                    :class="{'active': heroCurrentImage === index}"></li>
              </ol>
              <div class="carousel-inner h-100">
                <div class="carousel-item h-100" v-for="(img, index) in heroImages" :key="index"
                     :class="{'active': heroCurrentImage === index}">
                  <img :src="img">
                </div>
              </div>
              <button class="carousel-control-prev" @click="heroPreviousImage" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button class="carousel-control-next" @click="heroNextImage" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section churches">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-5">
          <div class="">
            <h2 class="title">Igrejas da Unidade Pastoral de Chelas</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="learn-more-box">
            <a
                class="d-block"
            >
              <img src="@/assets/images/sta_beatriz/igreja.jpg" alt="church3-home-pic2">
            </a>
            <div class="info">
              <h4 class="title">Sta. Beatriz da Silva</h4>
              <div class="description">
                <h5 class="mb-0">Missas Semanais</h5>
                <div class="desc">
                  <span>3ª a 6ª Feira às 18h</span>
                </div>
                <h5 class="mt-2 mb-0">Missas Dominicais</h5>
                <div class="desc">
                  <span>Sábado às 17h30</span><br>
                  <span>Domingo às 10h</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="learn-more-box">
            <a
                class="d-block"
            >
              <img src="@/assets/images/sta_clara/igreja.jpg" alt="church3-home-pic2">
            </a>
            <div class="info">
              <h4 class="title">Sta. Clara</h4>
              <div class="description">
                <h5 class="mb-0">Missas Semanais</h5>
                <div class="desc">
                  <span>3ª a 6ª Feira às 19h</span>
                </div>
                <h5 class="mt-2 mb-0">Missas Dominicais</h5>
                <div class="desc">
                  <span>Sábado às 19h</span><br>
                  <span>Domingo às 9h e às 10h30</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="learn-more-box">
            <a
                class="d-block"
            >
              <img src="@/assets/images/sao_max/igreja.jpg" alt="church3-home-pic2">
            </a>
            <div class="info">
              <h4 class="title">São Maximiliano de Kolbe</h4>
              <div class="description">
                <h5 class="mb-0">Missas Semanais</h5>
                <div class="desc">
                  <span>3ª a 6ª Feira às 18h30</span>
                </div>
                <h5 class="mt-2 mb-0">Missas Dominicais</h5>
                <div class="desc">
                  <span>Sábado às 18h30</span><br>
                  <span>Domingo às 9h e às 11h</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" v-if="news || calendar">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 text-center mb-0" v-if="news">
          <h4 class="mb-4 fw-bold">Avisos</h4>
          <table class="table">
            <tbody>
            <tr v-for="item in news" :key="item.bulletin_id">
              <th style="vertical-align: middle;" class="text-md-center text-start" >{{item.title}}</th>
              <td><button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#newsModal" @click.prevent="openNews(item)">Abrir Aviso</button></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="col-12 col-md-6 text-start mb-0 pt-md-0 pt-4" v-if="calendar">
          <h4 class="mb-4 text-center fw-bold">Calendário</h4>
          <table class="table table-hover">
            <tbody>
            <tr class="" v-for="(val, key, index) in calendar" :key="key">
              <th style="vertical-align: top; text-align: center">{{key.split(' ')[0]}}<br>{{key.split(' ')[1].toUpperCase()}}</th>
              <td>
                <div v-for="item in val" :key="item.timetable_id">
                  <div style="border-bottom: 1px solid antiquewhite;" class="mb-3">
                    <b>{{item.time}}</b> {{item.type_name}} {{item.notes}} {{item.place}}
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="section pb-0" style="background-color: #E8EAF6;">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-5">
          <div class="mb-2">
            <h2 class="title">“Se pedirdes alguma coisa em meu nome, Eu o farei” (Jo 14,14)</h2>
            <h6  class="title">(tema do ano pastoral 2023-24)</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <div class="mb-2">
            <p>Além deste guia bíblico, o ano pastoral é marcado por numerosos eventos, diocesanos e franciscanos. A 16 de Setembro começou o Ano Vicentino, lembrando os 850 anos da chegada das relíquias do santo diácono Vicente, mártir. Até 16 de setembro 2024 haverá iniciativas litúrgicas e culturais para conhecer melhor este santo, padroeiro da Diocese e da cidade. Papa Francisco proclamou um ano de Indulgência plenária.
            A família franciscana, de 2023 a 2026, tem cinco centenários para celebrar:</p>
            <ul>
              <li>800 anos da aprovação da Regra de s. Francisco (29 de Novembro 2023)</li>
              <li>800 anos do Presépio de s. Francisco a Greccio (25 de Dezembro 2023)</li>
              <li>800 anos da impressão dos Estígmas de s. Francisco (17 de Setembro de 2024)</li>
              <li>800 anos da composição do Cântico das Criaturas (Primavera 2025)</li>
              <li>800 anos da morte de s. Francisco de Assis (4 de Outubro 2026)</li>
            </ul>
            <p>São todas ocasiões para louvar juntos o Senhor e seguir mais de perto os seus passos.</p>
          </div>
        </div>
      </div>
      </div>
      </div>

      <div class="section">
        <div class="container">
      <div class="row home-gallery">
        <div class="col-md-3">
          <div class="info">
            <h4><b>Galeria</b></h4>
            <p> Nesta galeria encontra algumas fotos da nossa Unidade Pastoral. As fotos presentes
              mostram momentos litúrgicos, atividades associadas à Jornadas Mundias da Juventude e algumas atividades
              com
              idosos, jovens, crianças da nossa comunidade.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <img class="" src="@/assets/images/gallery/0.jpg" alt="church3-home-pic2"
               style="width: 100%; object-fit: cover" @click="$refs.gallery.start()">
        </div>
        <div class="col-md-3 d-flex flex-column">
          <img class="" src="@/assets/images/gallery/15.jpg" alt="church3-home-pic2" style="height: 100%"
               @click="$refs.gallery.start()">
          <div class="gallery-button-wrapper">
            <button class="btn btn-primary btn-lg w-100" @click="$refs.gallery.start()"><b>Ver Galeria</b> <i
                class="icon-right-thin"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <gallery ref="gallery"/>

  <!-- Modal -->
  <div class="modal fade" id="newsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content" v-if="selectedNew">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{selectedNew.title}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-html="selectedNew.description"></div>
        <div class="modal-footer">
          <a v-if="selectedNew.pdf_url || selectedNew.attachment_url" target="_blank" :href="selectedNew.pdf_url || selectedNew.attachment_url" class="btn btn-primary">Ver Anexo</a>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    news: null,
    calendar: null,
    selectedNew: null,
    timer: null,
    heroCurrentImage: 0,
    heroImages: [
      require("@/assets/images/gallery/23.jpg"),
      require("@/assets/images/gallery/2.jpg"),
      require("@/assets/images/gallery/34.jpg"),
    ],
  }),
  methods: {
    openNews: function(item) {
      this.selectedNew = item;
    },
    heroPreviousImage: function () {
      let val = this.heroCurrentImage - 1
      if (val < 0)
        val = this.heroImages.length - 1

      this.heroCurrentImage = val
    },
    heroNextImage: function () {
      let val = this.heroCurrentImage + 1
      if (val >= this.heroImages.length)
        val = 0

      this.heroCurrentImage = val
    },
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.heroNextImage()
    }, 3000)

    fetch("https://app.upchelas.com/api/bulletins/list", {
      method: "POST",
    }).then(async (res) => {
      const data = await res.json()
      this.news = data.data.bulletins.filter((obj) => obj.type !== 0).slice(0, 10).map((obj) => {
        return {
          ...obj,
          description: obj.description.replaceAll('\n', '<br><br>')
        }
      });
    })

    fetch("https://app.upchelas.com/api/timetables/list", {
      method: "POST",
    }).then(async (res) => {
      const data = await res.json()

      const days = {}
      data.data.timetables.forEach((obj) => {
        const ts = new Date(obj.date*1000)

        const dia = ts.toLocaleDateString('pt-PT', { day: '2-digit' });
        const mes = ts.toLocaleDateString('pt-PT', { month: 'short' }).replace('.', '');

        const date = `${dia} ${mes}`
        const time = ts.toISOString().slice(11, 16)
        if (!days[date]) {
          days[date] = []
        }

        days[date].push({
          ...obj,
          ts: ts,
          date: date,
          time: time,
        })
      })

      this.calendar = days
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.home-hero {
  /*min-height: 60vh;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*align-items: center;*/
  column-gap: 1rem;

  .hero-img {
    height: 100%;
    width: 100%;
    min-height: 400px;

    @media (max-width: 767.98px) {
      min-height: unset;
      max-height: 250px;
    }

    .carousel-control-prev, .carousel-control-next {
      opacity: 1;
    }

    img {

      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 0.25rem;

      @media (max-width: 767.98px) {
        height: 250px;
      }
    }

    .carousel-item {
      opacity: 0;
      transition: opacity .5s ease-in;
      -webkit-transition: opacity .5s ease-in;
      -moz-transition: opacity .5s ease-in;
      -o-transition: opacity .5s ease-in;
      display: block;
    }

    .carousel-item.active {
      opacity: 1;
    }
  }

  .desc {
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;

    @media (max-width: 767.98px) {
      margin-top: 1em;
      margin-bottom: 1em;
      font-size: 18px;
      text-align: center;
    }
  }

  .side-description {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-weight: 700;
    font-size: 64px;
    letter-spacing: -0.5px;
    line-height: 1em;

    @media (max-width: 767.98px) {
      font-size: 50px;
      text-align: center;
      margin-top: 0.5em;
    }
  }


  @media (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
}

.hero-submenu a {
  color: white;
  border: 0 !important;
  border-bottom: 3px solid transparent !important;
  font-size: 17px;
}

.hero-submenu ul {
  border-bottom: 0;
}

.hero-submenu li {
  margin: 0 2em;
}

.hero-submenu a:hover {
  border-color: white !important;
  border: 0px;
  border-bottom: 3px solid !important;
}

.churches {
  background-color: #E8EAF6;

  .learn-more-box {
    text-align: center;
  }
}

.schedule-box {
  text-align: center;
  background-color: #9FA8DA;
  padding: 1em;
  border-radius: 0.3rem;

  .schedule-img {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.schedule-wrapper {
  @media (max-width: 767.98px) {
    > div:not(:first-child) {
      margin-top: calc(var(--bs-gutter-x) * 0.5)
    }
  }
}

.home-gallery {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.3rem;
  }

  .info {
    color: black;
    background-color: #9FA8DA;
    border-radius: 0.3rem;
    padding: 2em;
    height: 100%;
  }

  @media (max-width: 767.98px) {
    > div:not(:first-child) {
      margin-top: calc(var(--bs-gutter-x) * 0.5)
    }
  }
}

.gallery-button-wrapper {
  margin-top: var(--bs-gutter-x);

  @media (max-width: 767.98px) {
    margin-top: calc(var(--bs-gutter-x) * 0.5)
  }
}
</style>
<script setup>
import Gallery from "@/components/Gallery.vue";
</script>
